import React from "react";
import SectionLabel from "./SectionLabel";

function About() {
    return <>
        <div className={"about container bg-image"}>

            <div className={"row"}>
                <div className={"col"}>
                    </div>

                <div className={"row"}>
                    <div className={"col-1"}></div>
                    <div className={"col-7 about-content"}>
                        <p>In the past, sharks were known as "sea dogs." Today, sharks are the masters of the water world. They are fast, intelligent and built to thrive in multiple environments. </p>

                            <h3>Here at mediashark, we’re shark-like when it comes to creating multi-channel demand generation campaigns that increase qualified leads, improve conversion rates, and accelerate growth. </h3>

                        <div className={"col "}>
                            <hr/>
                            <h2>Stop Fishing for Results</h2>
                            <hr/>
                            <p>We provide an integrated service offering to help companies start up their demand programs or improve their existing programs.
                            </p>
                            <ul>
                                <li>With mediashark on board, our clients become more competitive in the market. </li>
                                <li>We help them tell their story and articulate their value proposition.</li>
                                <li>We create a repeatable growth engine that has a measurable impact.</li>

                            </ul>
                            <br/>

                        </div>
                    </div>

                </div>

            </div>


        </div>

    </>
}
export default About