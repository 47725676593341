import React from 'react';
import './App.scss';

import {Routes, Route} from "react-router-dom"
import HeaderBar from "./HeaderBar"
import Home from "./Home";
import Contact from "./Contact";
import Services from "./Services";
import Clients from "./Clients";
import SharkBites from "./SharkBites";
import OurPeople from "./OurPeople";
 import logo from "./img/mediashark_logorefresh.svg"

function App() {
    return (
        <>
            {/*<div className={"row"}>*/}
            {/*<img src={logo} className={'placeholder-logo'}/>*/}
            {/*</div>*/}
            <HeaderBar></HeaderBar>
            <div style={{position:"relative",minHeight:"100px"}}><p>mediashark!</p></div>

            <Routes>
                <Route path={"/"} element={<Home />}></Route>
                <Route path={"/offer"} element={<Services />}></Route>
                <Route path={"/clients"} element={<Clients />}></Route>
                <Route path={"/contact"} element={<Contact />}></Route>
                <Route path={"/sharkbites"} element={<SharkBites />}></Route>
                <Route path={"/ourpeople"} element={<OurPeople />}></Route>

            </Routes>
            <p className={'copy'}>&copy;2024 mediashark inc. All rights reserved. </p>
        </>
    );
}

export default App;
