import SectionLabel from "./SectionLabel";
import React from "react";
import GreenBar from "./GreenBar";

function Contact() {
    return <>
        <div className={"contact container bg-image"}>
            <a id="contact"></a>
            <div className={"row"}>
                <div className={"col"}>
                    <SectionLabel label={"CONTACT US"}></SectionLabel>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-5"}>
                <p>Our mission is simple - exceed client marketing objectives through smart media development and execution. Contact us now to get started.</p>
                    <div data-mdb-input-init className="form-floating mb-4">
                        <input type="text" id="form4Example1" className="form-control"/>
                        <label className="form-label" htmlFor="form4Example1">Name</label>
                    </div>

                    <div data-mdb-input-init className="form-floating mb-4">
                        <input type="email" id="form4Example2" className="form-control"/>
                        <label className="form-label" htmlFor="form4Example2">Email address</label>
                    </div>

                    <div data-mdb-input-init className="form-floating mb-4">
                        <textarea className="form-control" id="form4Example3" rows={4}></textarea>
                        <label className="form-label" htmlFor="form4Example3">Message</label>
                    </div>

                    <button data-mdb-ripple-init type="button" className="btn btn-primary btn-block mb-4">Send</button>
                    <p>Email <a href={"mailto:info@mediashark.com"}>info@mediasharkinc.com</a></p>
                    <p>Based in Portland, Oregon with clients throughout the US and Canada.</p>

                </div>
            </div>
        </div>
        <GreenBar></GreenBar>
    </>
}

export default Contact