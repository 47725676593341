import facts from "./content/sharkfacts.json";
import React from "react";

function GreenBar(params:any) {
    function getQuote():string{
        return facts.facts[Math.floor(Math.random() * facts.facts.length)]
    }
    return <div className={"col-12 about-footer"}>
        <p>{getQuote()}</p>
    </div>;
}

export default GreenBar