import SectionLabel from "./SectionLabel";
import React from "react";
import imag from "./img/AdobeStock_300594028_Preview.jpeg"
import GreenBar from "./GreenBar";

function Bite() {
    return <>
        <div className={"bite row container"}>
            <div className={"col-2 img-holder"}>
                <div className={"date-holder"}>
                    {/*<div className={"month"}>JAN</div>*/}
                    {/*<div className={"day"}>17</div>*/}
                    {/*<div className={"year"}>2024</div>*/}
                </div>
                <img src={imag} className={"blog-image"}/>
            </div>
            <div className={"entry col-10"}>
                <h2>Have you jumped on the AI bandwagon yet?</h2>
                <h3>Or are you still figuring out when it can be actually helpful? If you manage events, read on for a
                    very clever and useful implementation.</h3>
                <h4>Excerpt from <em>martech</em></h4>
                <p><strong>EXCERPT:</strong> We have a user conference and we look at all the survey data from it. We do
                    quantitative data analysis internally but there are a lot of verbatim comments, and they are hard to
                    distill down. The team put the comments into the AI and it found themes and patterns that we hadn’t
                    seen before. We grew that into six or seven key findings that informed our strategy along with the
                    quantitative data. We wouldn’t have gotten that on our own.</p>
                <div className={"more-bite"}>
                    <a href={"https://martech.org/how-to-do-an-ai-implementation-for-your-marketing-team/"}
                       target={"new"}>
                        <button>MORE</button>
                        <p>FROM: <em>“How to do an AI implementation for your marketing
                            team”</em><br/><strong>MarTech</strong></p>
                    </a>
                </div>
            </div>
        </div>
        <div className={"bite row container"}>
            <div className={"col-2 img-holder"}>
                <div className={"date-holder"}>
                    {/*<div className={"month"}>JAN</div>*/}
                    {/*<div className={"day"}>17</div>*/}
                    {/*<div className={"year"}>2024</div>*/}
                </div>
                <img src={imag} className={"blog-image"}/>
            </div>
            <div className={"entry col-10"}>
                <h2>Ever had to send an “Oops” follow-up email after a scheduled one?</h2>
                <h3>"We’ve all been there–the email has been written, edited, proofed, and scheduled–and then deployed,
                    only to discover that there was a mistake in it. Cringe-y!</h3>
                <h4>Excerpt from <em>martech</em></h4>
                <p><strong>EXCERPT:</strong> Make sure you’re on great terms with the customer service lead. Somebody
                    who gets an email with the subject line “Hi Good prospect from the annual meeting” will forward it
                    to customer service. Make sure they know they have your ear and that you will be very grateful when
                    they clue you into this sort of mistake. For some reason, it can be like pulling teeth to get CSRs
                    to tell you when something stupid happens. Encourage them regularly.</p>
                <div className={"more-bite"}>
                    <a href={"https://martech.org/how-to-keep-your-marketing-automation-campaigns-from-ruining-your-week"}
                       target={"new"}>
                        <button>MORE</button>
                        <p>FROM: <em>“How to keep your marketing automation campaigns from ruining your
                            week”</em><br/><strong>MarTech</strong></p>
                    </a>
                </div>
            </div>
        </div>
        <div className={"bite row container"}>
            <div className={"col-2 img-holder"}>
                <div className={"date-holder"}>
                    {/*<div className={"month"}>JAN</div>*/}
                    {/*<div className={"day"}>17</div>*/}
                    {/*<div className={"year"}>2024</div>*/}
                </div>
                <img src={imag} className={"blog-image"}/>
            </div>
            <div className={"entry col-10"}>
                <h2>Do you know the new spam rules from Gmail?</h2>
                <h3>Email is still a critical part of most marketers' promotional plans and it’s more important than
                    even to do it right. Here’s some of the latest on how to keep out of the dreaded spam folders.</h3>
                <h4>Excerpt from <em>leadIQ</em></h4>
                <p><strong>PRO TIP:</strong> Kelsey Cowher, Director of Account Development at CloudZero, suggests
                    including a post-script with valuable information in each email because of the way our eyes scan
                    messages. At the same time, you need to avoid “trigger words” that set off spam filters — like a
                    percent symbol. “Google doesn’t like that. Just telling you right now. If you haven’t, don’t put it
                    in there,” she says.</p>
                <div className={"more-bite"}>
                    <a href={"https://leadiq.com/blog/sales-experts-share-tips-to-help-gtm-teams-prepare-for-new-gmail-spam-rules"}
                       target={"new"}>
                        <button>MORE</button>
                        <p>FROM: <em>“Sales experts share tips to help GTM teams prepare for new Gmail spam
                            rules”</em><br/><strong>leadIQ</strong></p>
                    </a>
                </div>
            </div>
        </div>
        <div className={"bite row container"}>
            <div className={"col-2 img-holder"}>
                <div className={"date-holder"}>
                    {/*<div className={"month"}>JAN</div>*/}
                    {/*<div className={"day"}>17</div>*/}
                    {/*<div className={"year"}>2024</div>*/}
                </div>
                <img src={imag} className={"blog-image"}/>
            </div>
            <div className={"entry col-10"}>
                <h2>Content syndication and ABM--a powerful combination</h2>
                <h3>Most of our clients do content syn and ABM, but too often then can be siloed. Watch this webinar to
                    discover how to combine the two for higher-quality leads.</h3>
                <h4>Excerpt from <em>Marketing Profs</em> Webinar</h4>
                <p><strong>OVERVIEW:</strong> If you're using either content syndication or ABM advertising in your
                    marketing strategy—but not both—you're not reaching a big segment of your market. These two tactics
                    complement one another. And, combined, they create a single, hyper-targeted way to generate and
                    nurture engaged leads among your ideal customer accounts. Watch this to get higher content ROI and
                    more accurate data with your marketing strategy.</p>
                <div className={"more-bite"}>
                    <a href={"https://martech.org/how-to-keep-your-marketing-automation-campaigns-from-ruining-your-week"}
                       target={"new"}>
                        <button>MORE</button>
                        <p>FROM: <em>“Content Syndication + ABM Advertising: Better Together”</em><br/><strong>Marketing
                            Profs</strong></p>
                    </a>
                </div>
            </div>
        </div>
    </>
}

function SharkBites() {
    return <>
        <div className={"sharkbites container bg-image"}>
            <div className={"row"}>
                <div className={"col"}>
                    <SectionLabel label={"Shark Bites"}></SectionLabel>
                </div>
            </div>
        </div>
        <Bite></Bite>
        <GreenBar></GreenBar>
    </>
}

export default SharkBites