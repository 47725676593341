import onepass from "./img/mslogos/1password.png";
import fifteenfive from "./img/mslogos/15five.png";
import achievers from "./img/mslogos/achievers.png";
import acton from "./img/mslogos/acton.png";
import ciena from "./img/mslogos/ciena.png";
import databricks from "./img/mslogos/databricks.png";
import equinix from "./img/mslogos/equinix.png";
import fitbit from "./img/mslogos/fitbit.png";
import fiton from "./img/mslogos/fiton.png";
import lever from "./img/mslogos/lever.png";
import momentive from "./img/mslogos/momentive.png";
import smarsh from "./img/mslogos/smarsh.png";
import spycloud from "./img/mslogos/spycloud.png";
import zoomInfo from "./img/mslogos/ZoomInfo.jpg";
import React from "react";
import SectionLabel from "./SectionLabel";
import QuoteBar from "./QuoteBar";
import GreenBar from "./GreenBar";

function Quote() {


    const quotes = [
        {
            blurb: "Collaborative, Accountable, Passionate, Innovative, these are some of the adjectives I would use to describe mediashark's team. Over the years I have worked with them in multiple organizations and they have been a great partner and extension of my team, be it in a smaller start-up or a bigger enterprise.",
            person: "N.M",
            company: ""
        },
        {
            blurb: "The team at mediashark is so great. They take such a load off my plate by helping get our content syndication and other marketing programs up and running. It's such a relief - giving me the time and energy to focus on other tasks. Mediashark is an integral part of our marketing efforts!",
            person: "Alex A.",
            company: "",
        },
        {
            blurb: "Mediashark isn't just another agency; they're an indispensable extension of our marketing team. Their deep-rooted expertise in media buying has consistently propelled our lead generation efforts to new heights. What truly sets them apart is their seamless handling of our business demands; even amidst a deluge of tasks, they never miss a beat.",
            person: "Keshila Shannon",
            company: "SVP of Marketing, Lever"
        },
        {
            blurb: "We’ve worked with mediashark for over 7 years now and it’s been a great partnership! They are reliable, quick to respond, and work hard to ensure we meet our demand generation goals. They understand the need to show positive ROI and we can always count on their industry knowledge and expertise to help run successful campaigns.",
            person: "Olivia Summut",
            company: "Senior Demand Gen Specialist, Achievers"
        },
    ]
    return <>
        <div className={"row client-quotes container-fluid"}>
            <div className={"col"}></div>
                <div className={"col"}>
                    <p className={"blurb"}>{quotes[0].blurb}</p>
                    <p className={"attribution"}>&mdash;{quotes[0].person}</p>
                    <p className={"company"}>{quotes[0].company}</p>
                </div>
            <div className={"col"}></div>
        </div>
        <div className={"row client-quotes container-fluid"}>
            <div className={"col"}></div>
                <div className={"col"}>
                    <p className={"blurb"}>{quotes[1].blurb}</p>
                    <p className={"attribution"}>&mdash;{quotes[1].person}</p>
                    <p className={"company"}>{quotes[1].company}</p>
                </div>
            <div className={"col"}></div>
        </div>
        <div className={"row client-quotes container-fluid"}>
            <div className={"col"}></div>
                <div className={"col"}>
                    <p className={"blurb"}>{quotes[2].blurb}</p>
                    <p className={"attribution"}>&mdash;{quotes[2].person}</p>
                    <p className={"company"}>{quotes[2].company}</p>
                </div>
            <div className={"col"}></div>
        </div>
        <div className={"row client-quotes container-fluid"}>
            <div className={"col"}></div>
                <div className={"col"}>
                    <p className={"blurb"}>{quotes[3].blurb}</p>
                    <p className={"attribution"}>&mdash;{quotes[3].person}</p>
                    <p className={"company"}>{quotes[3].company}</p>
                </div>
            <div className={"col"}></div>
        </div>

    </>
}

function Clients() {


    return <>
        <div className={"portfolio container bg-image"}>
            <a id="clients"></a>
            <div className={"row"}>
                <div className={"col"}>
                    <SectionLabel label={"Our Clients"}/>
                </div>
            </div>
            <h3>Sharks appeared in the ocean 455 million years ago. That’s some serious longevity.
                We believe in
                longevity when it comes to our clients - if you treat your clients well and deliver good outcomes you
                will have your clients for a very long time. </h3>
            <p>It’s much easier to consistently provide excellent service and demonstrable results than to be constantly
                fishing for new clients. This is why, in a marketing landscape where the average client relationship
                lasts 1 - 3 years, we have clients we’ve worked with for more than 10 years, as they jump ship from one
                company to another. (You know who you are and we love you!)
            </p><p>Our client list is focused in high tech, HR, and marketing verticals. Some of current and recent
            clients include:</p>
            <div className={"row logos"}>
                <div className={"col"}>
                    <img src={onepass}/>
                </div>
                <div className={"col"}>
                    <img src={fifteenfive}/>
                </div>
                <div className={"col"}>
                    <img src={achievers}/>
                </div>
                <div className={"col"}>
                    <img src={acton}/>
                </div>
                <div className={"col"}>
                    <img src={ciena}/>
                </div>
                <div className={"col"}>
                    <img src={databricks}/>
                </div>
                <div className={"col"}>
                    <img src={equinix}/>
                </div>
                <div className={"col"}>
                    <img src={fitbit}/>
                </div>
                <div className={"col"}>
                    <img src={fiton}/>
                </div>
                <div className={"col"}>
                    <img src={lever}/>
                </div>
                <div className={"col"}>
                    <img src={momentive}/>
                </div>
                <div className={"col"}>
                    <img src={smarsh}/>
                </div>
                <div className={"col"}>
                    <img src={spycloud}/>
                </div>
                <div className={"col"}>
                    <img src={zoomInfo}/>
                </div>
            </div>
        </div>
        <Quote></Quote>

        <GreenBar></GreenBar>
    </>
}

export default Clients