import React from "react";
import {Link} from "react-router-dom"

function HeaderBar() {
    return <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
        <div className="container-fluid">
            <a className="navbar-brand" href="/"><img alt="logo" src="/img/mediashark_logorefresh.svg"/></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            {/*<nav className="navbar navbar-expand-md sticky-top">*/}
            {/*<Link className="navbar-brand" to="/"><img alt="logo" src="/img/mediashark_logorefresh.svg"/></Link>*/}
            {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"*/}
            {/*        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">*/}
            {/*    <span className="navbar-toggler-icon"></span>*/}
            {/*</button>*/}
            {/*<div className="collapse navbar-collapse flex-grow-1 text-right " id="navbarSupportedContent">*/}
            {/*    <ul className="navbar-nav ms-auto">*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/">HOME</Link>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/offer">WHAT WE OFFER</Link>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/clients">OUR CLIENTS</Link>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/sharkbites">SHARK BITES</Link>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/ourpeople">OUR PEOPLE</Link>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <Link className="nav-link" to="/contact">CONTACT</Link>*/}
            {/*        </li>*/}

            {/*    </ul>*/}
            {/*</div>*/}
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">HOME</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#offer">WHAT WE OFFER</a>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/clients">OUR CLIENTS</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/sharkbites">SHARK BITES</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/ourpeople">OUR PEOPLE</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contact">CONTACT</Link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
}

export default HeaderBar