import SectionLabel from "./SectionLabel";
import React from "react";
import Ann from "./img/people/Ann.jpg"
import Lisa from "./img/people/lisa.jpg"
import Mitzi from "./img/people/mitzi.jpg"
import Sarah from "./img/people/sarah.jpg"
import QuoteBar from "./QuoteBar";

function Person({
                    personName,
                    personTitle,
                    blurb,
                    photo
                }: { personName: string, personTitle: string, blurb: string, photo: any }) {
    return <div className={"person col"}>
        <img className={"staffPhoto"} src={photo}/>
        <h3>{personName}</h3>
        <h4>{personTitle}</h4>
        <p>"{blurb}"</p>
    </div>
}

function OurPeople() {
    return <>
        <div className={"ourpeople container bg-image"}>
            <a id="ourpeople"></a>
            <div className={"row"}>


                <div className={"col"}>
                    <SectionLabel label={"Our People"}></SectionLabel>
                </div>
            </div>
        </div>
        <div className={"row container-fluid"}>
            <div className={"col"}>
                <Person personName={"Ann McMullen"} personTitle="Owner & Chief"
                        blurb={"I hire smart people, I’m obsessed with goats and can play exactly one song on the banjo."}
                        photo={Ann}></Person>
            </div>
            <div className={"col"}>
                <Person personName={"Sarah Gilbert"} personTitle="Account Director"
                        blurb={"I was the very first mediashark hire so I’m #1!"} photo={Sarah}></Person>

            </div>
            <div className={"col"}>
                <Person personName={"Lisa Fero"} personTitle="Project Manager"
                        blurb={"Having a daughter means I have exceptional negotiation skills."} photo={Lisa}></Person>
            </div>
            <div className={"col"}>
                <Person personName={"Mitzi Sandman"} personTitle="Account Director"
                        blurb={"Name it. I’ve done it. Except bungee jumping."} photo={Mitzi}></Person>
            </div>
        </div>
        <p></p>
        <QuoteBar></QuoteBar>
    </>
}

export default OurPeople