import React from "react";
import HeaderBar from "./HeaderBar";
import {Link} from "react-router-dom";

function Homepage() {
    return <div className={"home container min-vh-100 bg-image"}>
        <div className={"headline"}>
            <h3>No More Swimming In Circles</h3>
            <h1 className={"big-green"}>DEMAND GEN</h1>
            <h1>THAT WORKS</h1>
            <div className="row">

                <div className={"col-4"}></div>
                <div className={"col-4"}>
                    <div className={'drop-a-line'}>
                        <div className={"pre-button"}>•</div>
                        <div className={"button-line"}></div>
                        <Link to="/contact"><button>DROP A LINE</button></Link>
                    </div>
                </div>
                <div className={"col-4"}></div>
            </div>
        </div>
    </div>
}
export default Homepage