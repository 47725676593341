import React from "react";

function QuoteBar() {
    const quotes= [
        {
            blurb:"Collaborative, Accountable, Passionate, Innovative, these are some of the adjectives I would use to describe mediashark's team. Over the years I have worked with them in multiple organizations and they have been a great partner and extension of my team, be it in a smaller start-up or a bigger enterprise.",
            person:"N.M",
            company:""
        },
        {
            blurb:"The team at mediashark is so great. They take such a load off my plate by helping get our content syndication and other marketing programs up and running. It's such a relief - giving me the time and energy to focus on other tasks. Mediashark is an integral part of our marketing efforts!",
            person:"Alex A.",
            company:"",
        },
        {
            blurb:"Mediashark isn't just another agency; they're an indispensable extension of our marketing team. Their deep-rooted expertise in media buying has consistently propelled our lead generation efforts to new heights. What truly sets them apart is their seamless handling of our business demands; even amidst a deluge of tasks, they never miss a beat.",
            person:"Keshila Shannon",
            company:"SVP of Marketing, Lever"
        },
        {
            blurb:"We’ve worked with mediashark for over 7 years now and it’s been a great partnership! They are reliable, quick to respond, and work hard to ensure we meet our demand generation goals. They understand the need to show positive ROI and we can always count on their industry knowledge and expertise to help run successful campaigns.",
            person:"Olivia Summut",
            company:"Senior Demand Gen Specialist, Achievers"
        },
    ]
    const quote = quotes[Math.floor(Math.random()*4)];

    return <div className={"col-12 service-footer bg-image"}>
        <h2>Our clients love us. you will, too.</h2>
        <hr/>
        <p><span className={"bigquote"}>&ldquo;</span>{quote.blurb}<span className={"bigquote"}>&rdquo;</span></p>
        <p>&mdash;{quote.person}</p>
        <p>{quote.company}</p>
    </div>;
}

export default QuoteBar