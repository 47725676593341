import GreenBar from "./GreenBar"
import React from "react";
import Homepage from "./Homepage";
import About from "./About";
import Services from "./Services";

function Home() {
    return <>

        <Homepage></Homepage>
        <p>.</p>
        <a id={"offer"}></a>
        <About></About>
        <Services></Services>
        <GreenBar></GreenBar>
    </>
}

export default Home
