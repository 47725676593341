import icon1 from "./img/icon1.png";
import icon2 from "./img/icon2.png";
import icon3 from "./img/icon3.png";
import icon4 from "./img/icon4.png";
import icon5 from "./img/icon5.png";
import React from "react";
import {Link} from "react-router-dom";
import GreenBar from "./GreenBar";
import QuoteBar from "./QuoteBar";
import SectionLabel from "./SectionLabel";

function Services() {
    const pickService = function (sectionNumber: number) {
        const serviceElements = [document.getElementById("service1"),document.getElementById("service2"),document.getElementById("service3"),document.getElementById("service4")]
        const serviceIcons = [document.getElementById("icon1"),document.getElementById("icon2"),document.getElementById("icon3"),document.getElementById("icon4")]
        serviceIcons.forEach((item)=>{
            // @ts-ignore
            item.className="inactive";
        })
        // @ts-ignore
        serviceIcons[sectionNumber].className="active";
        serviceElements.forEach((item)=>{
            // @ts-ignore
            item.className="inactive";
        })
        // @ts-ignore
        serviceElements[sectionNumber].className="active";
    }

    return <>
        <div className={"services container"}>
            <a id="services"></a>
            <div className={"service-content row"}>

                <div className={"col"}>
                    <div className={"col"}>
                        <SectionLabel label={"WHAT WE OFFER"}></SectionLabel> </div>
                    <h1>Results-driven media planning</h1>
                    <h3>Did you know that sharks have advanced sight and night vision as well the ability to sense
                        pressure and vibrations?</h3>
                    <p>We’re a bit like that too. Based on years of experience working across a variety of industries
                        and marketing channels in the B2B and SaaS space, we are able to look at the marketing landscape
                        and develop a strategy that will put you at the top of the food chain.</p>
                    <p>We tailor each program based on our client’s campaign parameters including KPIs, budget, timing,
                        and anything else relevant to ensure a successful outcome. And because we’re small, we
                        operate like an extension of your team – an in-house media planning department where we provide
                        both marketing strategy and execution as needed.

                    </p>
                    <ul>
                        <li>Want a full-year media plan covering demand gen, virtual events, display, and e-marketing
                            centered around your content, your company’s big announcements and industry events?
                        </li>
                        <li>Want a comprehensive quarterly plan that drives a specific number of top-of-the-funnel
                            leads?
                        </li>
                        <li>Want an awareness campaign that pinpoints your target audience in the most effective way?
                        </li>
                        <li>Want turn-key execution?</li>
                        <p></p>
                        <h4>If your answer is YES, <Link to={"/contact"}>drop us a line</Link></h4>
                    </ul>

                    <div className={"all-services col container-fluid"}>
                        <div className={"row "}>

                        </div>
                        <div className={"row "}>
                            <div className={"col-1"}></div>
                            <div className={"col-1 service-icons"}>
                                <span className="icon" onClick={()=>pickService(0)}>
                                    <p><img src={icon1} className={"active"} id={"icon1"}/>
                                        Strategy</p>
                                </span>
                                <hr/>
                                <span className="icon" onClick={()=>pickService(1)}>
                                    <p><img src={icon2} className={"inactive"} id={"icon2"}/>
                                        Offerings</p>
                                </span>
                                <hr/>
                                <span className="icon" onClick={()=>pickService(2)}>
                                    <p><img src={icon3} className={"inactive"} id={"icon3"}/>
                                    Vendor Management
                                    </p>
                                </span>
                                <hr/>
                                <span className="icon" onClick={()=>pickService(3)}>
                                    <p><img src={icon4} className={"inactive"} id={"icon4"}/>
                                        Process & Reporting
                                    </p>
                                </span>
                                <hr/>
                                {/*<img src={icon5} className={"inactive"} id={"icon5"}/>*/}
                                {/*<hr/>*/}
                            </div>
                            <div className={"col-7 services-select strategy"}>
                                <div className={"active"} id={"service1"}>
                                    <h2>CORE CAPABILITIES</h2>
                                    <h1>Strategy</h1>
                                    <ul>
                                        <li>We perform rigorous media research aligned with your industry to make sure
                                            your marketing efforts are in front of the right audience in the right
                                            channels on the right third-party media brands
                                        </li>
                                        <li>We constantly monitor the digital landscape for new opportunities</li>
                                        <li>We offer you keen insight gained from our expertise collated and compiled
                                            across multiple industries, clients, and media vendors
                                        </li>
                                        <li>We make sure your marketing initiatives are aligned with industry best
                                            practices
                                        </li>
                                    </ul>
                                </div>
                                <div className={"inactive"} id={"service2"}>
                                    <h2>OUR SERVICES:</h2>
                                    <h1>Offerings</h1>
                                    <ul>
                                        <li>Demand Generation, including ABM, ICP, and Intent Based</li>
                                        <ul>
                                            <li>Content Syndication</li>
                                            <li>Webinar management and promotion</li>
                                            <li>eMarketing</li>
                                            <li>Virtual Conferences</li>
                                        </ul>

                                        <li>Awareness: Display</li>
                                        <ul>
                                            <li>Social: LinkedIn</li>
                                            <li>Native: Taboola, Nativo</li>
                                            <li>Programmatic/DSPs: Integrate, Adroll, Rollworks</li>
                                            <li>Direct via publishing brands</li>
                                        </ul>
                                    </ul>
                                </div>
                                <div className={"inactive"}id={"service3"}>
                                    <h2>OUR SERVICES:</h2>
                                    <h1>Vendor Management</h1>
                                    <ul>
                                        <li>We leverage our strong relationships with publishers, ad platforms, and
                                            media tech companies to your advantage
                                        </li>
                                        <li>We review your vendor contracts to ensure you’re getting what you expect
                                        </li>
                                        <li>We make sure all your contracted activities are executed by publisher as
                                            contracted and planned
                                        </li>
                                    </ul>
                                </div>
                                <div className={"inactive"} id={"service4"}>
                                    <h2>OUR SERVICES:</h2>
                                    <h1>Process & Reporting</h1>
                                    <ul>
                                        <li>Digital media plans, deployment schedules with deliverables</li>
                                        <li>Regular status updates and meetings</li>
                                        <li>Lead management and tracking</li>
                                        <li>Monthly or quarterly reporting</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <QuoteBar></QuoteBar>

    </>
        ;
}

export default Services